<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Reviews
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      solo
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-row justify="end" class="px-5">
                    <v-dialog
                      :retain-focus="false"
                      v-model="dialog"
                      persistent
                      max-width="600px"
                      :key="dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                          Add
                        </v-btn>
                      </template>
                      <v-card>
                        <v-form v-model="addcat" ref="addcat">
                          <v-card-title>
                            <span class="headline">Add Rating</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="name"
                                    label=" Name"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea
                                    outlined
                                    v-model="comment"
                                    label=" Comment"
                                    :rules="Rules"
                                    required
                                    hint="Text"
                                    name="input-7-1"
                                  ></v-textarea>
                                </v-col>

                                <v-col cols="12">
                                  <v-rating
                                    :value="parseFloat(rating)"
                                    background-color="black"
                                    color="black"
                                    :rules="Rules"
                                    required
                                    half-increments
                                    large
                                  ></v-rating>
                                </v-col>
                                <v-col cols="12">
                                  <v-img
                                    v-if="imagePreview"
                                    :src="imagePreview"
                                    width="150px"
                                    height="150px"
                                    class="align-center"
                                  /><br />
                                  <v-btn
                                    outlined
                                    @click="$refs.showImage.click()"
                                    >Upload Image
                                    <v-item-group>
                                      <input
                                        v-show="false"
                                        label="Upload Image"
                                        ref="showImage"
                                        id="file"
                                        type="file"
                                        style="display: none"
                                        @change="showImage"
                                      />
                                      <!-- </v-file-input> -->
                                    </v-item-group>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialogclose()"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!addcat"
                              @click="reviewadd()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="ratings"
                  :search="search"
                  hide-default-footer
                  :items-per-page="count"
                  class="elevation-1"
                >
                  <template v-slot:[`item._id`]="{ item }">
                    <v-icon small class="mr-2" @click="editcat(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small class="mr-2" @click="info(item._id)">
                      mdi-eye
                    </v-icon>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Rating?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:top>
                    <v-dialog
                      v-model="editdialog"
                      :retain-focus="false"
                      max-width="800px"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit Rating </span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.name"
                                  label=" Name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-textarea
                                  outlined
                                  v-model="editingitem.comment"
                                  label=" Comment"
                                  required
                                  hint="Text"
                                ></v-textarea>
                              </v-col>
                              <v-col cols="12">
                                <v-rating
                                  :value="parseFloat(editingitem.rating)"
                                  background-color="black"
                                  color="black"
                                  half-increments
                                  large
                                ></v-rating>
                              </v-col>
                            </v-row>
                            <v-row class="py-2 justify-center">
                              <v-col cols="12">
                                <v-img
                                  v-if="editingitem.image && !imagePreview"
                                  :src="baseURL + editingitem.image"
                                  width="150px"
                                  height="150px"
                                  style="align-center"
                                />
                                <v-img
                                  v-if="imagePreview"
                                  :src="imagePreview"
                                  v-show="showPreview"
                                  width="150px"
                                  height="150px"
                                  class="align-center"
                                />
                              </v-col>
                            </v-row>
                            <v-row class="py-2 justify-center">
                              <v-col cols="12" md="12">
                                <v-btn @click="$refs.showImage.click()"
                                  >Update Image
                                  <v-item-group>
                                    <input
                                      v-show="false"
                                      label="Upload Image"
                                      ref="showImage"
                                      id="file"
                                      type="file"
                                      style="display: none"
                                      @change="showImage"
                                    />
                                    <!-- </v-file-input> -->
                                  </v-item-group> </v-btn
                                ><br />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="editdialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="edit()">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
                <div v-if="pages > 1" class="pt-2 blue-grey lighten-5">
                  <v-pagination
                    :length="pages"
                    :total-visible="7"
                    v-model="currentPage"
                    color="#FF0000"
                  ></v-pagination>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      ServerError: false,
      imgId: "",
      image: "",
      file: null,
      showPreview: false,
      imagePreview: null,
      rating: 4.5,
      showsnackbar: false,
      msg: null,
      pages: 0,
      count: 20,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      comment: "",
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      headers: [
        { text: " Name", value: "name" },
        { text: "Comment", value: "comment" },
        { text: "Rating", value: "rating" },
        // { text: "Email", value: "email" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
      ratings: [],
      data: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
    currentPage() {
      this.getData();
    },
  },
  methods: {
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/topreview/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/topreview/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.ratings = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    reviewadd() {
      var data = {};
      data["name"] = this.name;
      data["comment"] = this.comment;
      data["rating"] = this.rating;
      axios({
        url: "/topreview/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;

          if (response.data.status) {
            this.imgId = response.data.id;
            this.uploadImage(response.data.id);
            this.getData();
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.comment = null;
            this.imagePreview = null;
            this.file = null;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["comment"] = this.editingitem.comment;
      data["rating"] = this.editingitem.rating;
      axios({
        url: "/topreview/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.imgId = response.data.id;
            if (this.editingitem.image) {
              this.uploadImage(this.editingitem._id);
              this.msg = "Edited Sucessfully";
              this.showsnackbar = true;
              this.editdialog = false;
            } else {
              this.getData();
              this.msg = "Edited Sucessfully";
              this.showsnackbar = true;
              // this.name = null;
              this.editdialog = false;
            }
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage(e) {
      // var files=e.target.files
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.showPreview = true;
      //reader.readAsDataURL(files[0]);
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      console.log("id", id);
      formData.append("image", this.file);

      axios({
        method: "POST",
        url: "/topreview/UploadImage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(rid) {
      this.$router.push({
        name: "ratingDetails",
        params: { rateid: rid },
      });
    },
  },
};
</script>

<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
